import ExternalLink from "./ExternalLink";
import styles from "./TimelineHeader.module.css";

type Props = {
  title: string;
  imgSrc: string;
  imgLandingPage: string;
  alt?: string;
};

export default function TimeLineHeader(props: Props) {
  return (
    <div className={styles.timelineSectionHeader}>
      <div className={styles.timelineSectionHeaderText}>{props.title}</div>
      {props.imgSrc && (
        <ExternalLink href={props.imgLandingPage}>
          <img className={styles.img} alt={props.alt} src={props.imgSrc} />
        </ExternalLink>
      )}
    </div>
  );
}
