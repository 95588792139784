import AppHeader from "./Components/Header";
import TimeLine from "./Components/Timeline";
import AppLayout from "./Layouts/AppLayout";

export default function App() {
  return (
    <AppLayout>
      <AppHeader />
      <TimeLine />
    </AppLayout>
  );
}
