import React from "react";
import styles from "./AppLayout.module.css";

type Props = { children: React.ReactNode };

export default function AppLayout({ children }: Props) {
  return (
    <div className={styles.app}>
      <div className={styles.appBoundary}>{children}</div>
    </div>
  );
}
