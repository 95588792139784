import { ReactNode } from "react";

type Props = {
  href: string;
  children?: string | ReactNode;
  className?: string;
};

export type ExternalLinks = {
    text: string;
    href: string;
  };

export default function ExternalLink({ href, children, className }: Props) {
  const onClick = (link: string) => {
    window.open(link, "_blank")?.focus();
    return;
  };

  return (
    <div className={className}>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={href}
        onClick={() => {
          onClick(href);
        }}
      >
        {children}
      </a>
    </div>
  );
}
