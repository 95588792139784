import ExternalLink, { ExternalLinks } from "./ExternalLink";
import styles from "./Header.module.css";

const headerLinks: ExternalLinks[] = [
  { text: "GitHub", href: "https://github.com/katiemcculloch" },
  { text: "LinkedIn", href: "https://www.linkedin.com/in/katie-mcculloch/" },
  {
    text: "Stack Overflow",
    href: "https://stackoverflow.com/users/10626380/katie-mcculloch",
  },
];

export default function AppHeader() {
  return (
    <div className={styles.header}>
      <div className={styles.title}>KATIE</div>
      <div className={styles.headerLinks}>
        {headerLinks.map((link) => (
          <ExternalLink className={styles.link} href={link.href}>
            {link.text}
          </ExternalLink>
        ))}
      </div>
    </div>
  );
}
