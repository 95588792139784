import { ReactNode } from "react";
import BulletPoint from "./BulletPoint";
import styles from "./TimelineBullet.module.css";

type Props = { children: string | ReactNode };

export default function TimeLineBullet({ children }: Props) {
  return (
    <div className={styles.bulletPointOuter}>
      <div className={styles.bullet}>
        <BulletPoint />
      </div>
      <div>{children}</div>
    </div>
  );
}
