import builtByGirlsLogo from "../built_by_girls_logo.png";
import dolthubLogo from "../dolthub_logo_teal.svg";
import hackReactorLogo from "../hack_reactor_logo.svg";
import mediamathLogo from "../mediamath_logo.svg";
import ucsbLogo from "../ucsb_logo.png";
import whenWeAllVoteLogo from "../when_we_all_vote_logo.png";
import ExternalLink, { ExternalLinks } from "./ExternalLink";
import styles from "./Timeline.module.css";
import TimelineBullet from "./TimelineBullet";
import TimelineHeader from "./TimelineHeader";
import TimelineSection from "./TimelineSection";

const dolthubBlogs: ExternalLinks[] = [
  {
    text: "LICENSE.md and README.md in Dolt",
    href: "https://www.dolthub.com/blog/2020-02-11-license-and-readme-in-dolt/",
  },
  {
    text: "F*#%! you (in 4 languages)",
    href: "https://www.dolthub.com/blog/2020-04-16-f-you-in-4-languages/",
  },
  {
    text: "Announcing DoltHub Issues",
    href: "https://www.dolthub.com/blog/2021-01-27-announcing-dolthub-issues/",
  },
  {
    text: "DoltHub got a Makeover",
    href: "https://www.dolthub.com/blog/2021-05-05-sql-first-dolthub/",
  },
  {
    text: "Data Bounty for Police Data Accessibility Project",
    href: "https://www.dolthub.com/blog/2021-05-28-bounty-announcement-with-pdap/",
  },
  {
    text: "Announcing the $10,000 Menus Data Bounty",
    href: "https://www.dolthub.com/blog/2021-07-07-announcing-us-menus-data-bounty/",
  },
  {
    text: "Wrapping up collaborative PDAP data bounty",
    href: "https://www.dolthub.com/blog/2021-07-09-pdap-bounty-wrap/",
  },
  {
    text: "How the menus bounty broke DoltHub",
    href: "https://www.dolthub.com/blog/2021-07-28-how-the-menu-bounty-broke-dolthub/",
  },
  {
    text: "Menus bounty retrospective",
    href: "https://www.dolthub.com/blog/2021-08-20-menus-bounty-retrospective/",
  },
];

export default function Timeline() {
  return (
    <div className={styles.timeline}>
      <TimelineSection text="Recent Work" />
      <TimelineHeader
        title="Full-Stack Software Engineer"
        imgSrc={dolthubLogo}
        imgLandingPage="https://www.dolthub.com"
        alt="DoltHub"
      />
      <div className={styles.timelineSectionDescription}>
        My time at DoltHub was formative. I was the 6th employee when we started
        working in a co-working space in Santa Monica in 2019, and became
        instrumental in building every iteration of the DoltHub website. I also
        contributed to Dolt, a MySQL database that can branch, merge and diff,
        and led the Data Bounties project. DoltHub is a place on the internet to
        host, share and query databases. Dolt is built on top of a Merkle DAG
        that stores the diff at each commit via content addressable hash.
        Working with and building this technology has made me a power user of
        Dolt and Git. I am deeply appreciative of the power that version control
        can bring to data.
      </div>
      <TimelineSection text="Projects" />
      <TimelineBullet>
        Lead of Data Bounties project that worked with community to build open
        source databases for money. This included domain research, schema design
        and data review.
      </TimelineBullet>
      <TimelineBullet>
        Site redesign which included new marketing pages, new repository page
        and database visualization, restructure of monorepo and full coverage
        unit and end-to-end user testing.
      </TimelineBullet>
      <TimelineBullet>Pull Request feature on DoltHub </TimelineBullet>
      <TimelineBullet>Issues feature on DoltHub </TimelineBullet>
      <TimelineBullet>
        Add LICENSE.md and README.md to Dolt command line tool.
      </TimelineBullet>

      <TimelineSection text="Blog Posts" />
      <div>
        {dolthubBlogs.map((blog) => (
          <TimelineBullet>
            <ExternalLink className={styles.link} href={blog.href}>
              {blog.text}
            </ExternalLink>
          </TimelineBullet>
        ))}
      </div>
      <TimelineSection text="Other experience" />
      <TimelineHeader
        alt="MediaMath"
        title="Engagement Manager"
        imgSrc={mediamathLogo}
        imgLandingPage="https://www.mediamath.com/"
      />
      <div>
        <TimelineBullet>
          Drove 30% annual growth in entertainment, ride service and B2B
          accounts with total quarterly budget of $3M
        </TimelineBullet>
        <TimelineBullet>
          Managed several account teams of 5-8 people spanning Engagement,
          Analytics, Engineering, Strategy and Optimization and Support
        </TimelineBullet>
        <TimelineBullet>
          Scripted SQL queries to analyze reach, engagement and conversion paths
          with an emphasis in mobile measurement
        </TimelineBullet>
        <TimelineBullet>
          Administered A/B and incrementality testing to measure media
          effectiveness, engagement and design
        </TimelineBullet>
        <TimelineBullet>
          Created custom UI mocks that solved for convoluted bulk workflows
        </TimelineBullet>
      </div>
      <TimelineSection text="Education" />
      <TimelineHeader
        alt="Hack Reactor"
        title="Software Engineering Immersive"
        imgSrc={hackReactorLogo}
        imgLandingPage="https://www.hackreactor.com/"
      />
      <TimelineHeader
        alt="UCSB"
        title="Bachelor of Science in Applied Mathematics"
        imgSrc={ucsbLogo}
        imgLandingPage="https://www.ucsb.edu/"
      />
      <TimelineBullet>
        Research of Dynamical Systems with an emphasis in{" "}
        <a href="https://en.wikipedia.org/wiki/Sinai%E2%80%93Ruelle%E2%80%93Bowen_measure">
          Sinai-Ruelle-Bowen measure
        </a>
      </TimelineBullet>
      <TimelineBullet>
        Courses: Real analysis, Numerical analysis, Linear algebra, Chaotic
        Dynamics and Bifurication Theory, Methods of analysis, Theory of Complex
        Variables
      </TimelineBullet>
      <TimelineSection text="Volunteering" />
      <TimelineHeader
        alt="Hack Reactor"
        title="Women in Tech Mentor and Alumni Speaker"
        imgSrc={hackReactorLogo}
        imgLandingPage="https://www.hackreactor.com/"
      />
      <TimelineHeader
        alt="Built By Girls"
        title="Advisor"
        imgSrc={builtByGirlsLogo}
        imgLandingPage="https://www.builtbygirls.com/"
      />
      <TimelineHeader
        alt="When We All Vote"
        title="Community Outreach Specialist"
        imgSrc={whenWeAllVoteLogo}
        imgLandingPage="https://whenweallvote.org/"
      />
    </div>
  );
}
